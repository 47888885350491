import React from 'react'
import { Element } from 'react-scroll'
import SEO from '../components/seo'

import PageWrapper from '../components/PageWrapper'

import Blogs from '../slices/Blogs'
import { Section } from '../components/Core'

const BlogPage = () => {
  return (
    <>
      <PageWrapper darkTheme={false}>
        <SEO title="Blog" description="Get the latest in real estate updates with East Avenue Realty."/>

        <Section>
          <Blogs hide={true}/>
        </Section>
      </PageWrapper>
    </>
  )
}
export default BlogPage
